import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Avatar from './Avatar';
import './polyfills';
import './App.css';
import './tailwind.scss';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/remake-me" element={<Avatar />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
