import React, { useEffect, useState } from 'react';
import './styles.css';
import logo from './assets/images/avatar-maker-logotype.png';
import img2 from './assets/images/dashboard2.png';
import { SetCookie } from './utils/cookies';

const Home = () => {
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [showDashboardLink, setShowDashboardLink] = useState(true);

  useEffect(() => {
    const referrer = document.referrer;
    const invalid_referrer = referrer === '' || referrer === window.location.href;

    if (invalid_referrer || window.self !== window.top) {
      setShowDashboardLink(false);
    }

    if (!invalid_referrer) {
      const referrer_url = new URL(referrer);
      setDashboardUrl(referrer_url.origin + '/my-account');
      SetCookie('back_link', referrer_url.origin + '/my-account', { path: '/' });
    }
  }, []);

  return (
    <>
      <div className="flex max-w-6xl mx-auto flex-col items-center justify-center py-2 min-h-screen">
        <div className="headeravatar flex flex-col xs:flex-row justify-between items-center w-full mt-3 border-b pb-5 sm:px-4 px-2 border-gray-500 gap-2 header-center">
          {showDashboardLink && (
            <a href={dashboardUrl} className="back-dashboard" aria-label="Back to Dashboard">
              <img
                alt="back button"
                src={img2}
                className="sm:w-5 sm:h-5 w-5 h-5"
                width={12}
                height={12}
              />
              Dashboard
            </a>
          )}
          <img src={logo} alt="Avatar Maker logo" className="logoimg text-center sm:h-[70px] mx-auto" />
        </div>

        <div className="flex flex-col items-center justify-center min-h-screen px-4 sm:px-8 py-12">
          <h1 className="text-[40px] sm:text-[72px] font-bold tracking-normal leading-tight text-black-300 text-center">
            Experience the Art <br />of Illusion{' '}
            <span className="relative whitespace-nowrap text-green-600">
              <span className="relative">using AI</span>
            </span>
          </h1>
          <h2 className="mt-8 text-base text-lg sm:text-xl sm:w-2/3 text-white-500 text-center leading-7">
            By uploading an image and utilizing prompts, users can easily modify various aspects of the image, allowing them to create their desired look with just a few simple clicks.
          </h2>
          <a
            className="mt-10 px-6 py-4 bg-[#16A34A] rounded-lg text-white hover:bg-green-500 transition text-xl font-extrabold"
            href="/remake-me"
          >
            Generate Avatar
          </a>
          <div className="flex flex-col mt-10">
            <div className="w-full px-4 sm:px-2 mx-auto mt-10">
              <iframe
                width="830"
                height="480"
                src="https://www.youtube.com/embed/Sd6CsmeBLIU?si=mb6CL8CPjZDefFEZ"
                title="Tutorial on how to use Avatar Maker"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ border: '1px solid #ccc' }}
              >
              </iframe>
            </div>
          </div>
        </div>
        <footer className="text-center h-16 sm:h-12 w-full sm:pt-2 pt-4 border-t mt-5 flex sm:flex-row flex-col justify-between items-center px-3 space-y-3 sm:mb-0 mb-3 border-gray-500"></footer>
      </div>
    </>
  );
};

export default Home;
